import type { ThemeMode } from '@web-solutions/core/interfaces/theme';
import type { QuizStep } from '@web-solutions/base-app/screens/quiz/interfaces';

import { QuizStepKind, QuizStepView } from 'core/containers/questions/types';

import { QUIZ } from 'src/constants/routes';

import { IMAGES } from './images';

export const STEPS: Record<string, QuizStep> = {
  [QUIZ.TRANSITION_WORK_FOR_YOU]: {
    type: 'transition',
    title: 'Know which colors and styles work for you',
    subTitle: 'Answer a few questions about your looks and fashion goals to let our AI stylist create a perfect wardrobe for you',
    image: IMAGES.TRANS_WORK_FOR_YOU,
  },
  [QUIZ.TRANSITION_YOU_NOT_ALONE]: {
    type: 'transition',
    title: 'Don’t worry, you’re not alone in this',
    subTitle: 'Millions of women know the struggle, so we’ve created Stylix to empower them to dress with confidence every day',
    image: IMAGES.TRANS_YOU_NOT_ALONE,
  },
  [QUIZ.TRANSITION_CAPSULE_WARDROBE]: {
    type: 'transition',
    title: 'Build a capsule wardrobe you’ll wear for years',
    subTitle: 'Based on your looks and lifestyle needs, our AI stylist will suggest timeless outfits that highlight your unique beauty',
    image: IMAGES.TRANS_CAPSULE_WARDROBE,
  },
  [QUIZ.TRANSITION_LOOK_BEST]: {
    type: 'transition',
    title: 'You deserve to look and feel your best',
    subTitle: 'We’re here to help women choose stylish outfits, look attractive, and always feel comfortable, no matter the occasion',
    image: IMAGES.TRANS_LOOK_BEST,
  },
  [QUIZ.TRANSITION_PERFECT_CUTS]: {
    type: 'transition',
    title: 'Let’s explore your perfect cuts and colors',
    subTitle: 'Our AI stylist will analyze your looks and give you personalized suggestions for clothes, accessories, and makeup',
    image: IMAGES.TRANS_PERFECT_CUTS,
  },


  [QUIZ.TRANSITION_WHICH_SUIT]: {
    type: 'transition',
    title: 'Know which colors and styles suit you',
    subTitle: 'A few questions to personalize your guide',
    image: IMAGES.TRANS_WHICH_SUIT,
  },
  [QUIZ.TRANSITION_NOTHING_TO_WEAR]: {
    type: 'transition',
    title: 'Got a wardrobe full of clothes but nothing to wear?',
    subTitle: 'No more hours wasted trying on clothes that just don\'t suit you!',
    description: 'In just a few quick questions, we will help you uncover your personal style and suggest outfits that will make you feel like a million bucks',
    image: IMAGES.TRANS_NOTHING_TO_WEAR,
  },
  [QUIZ.TRANSITION_WARDROBE_REGRETS]: {
    type: 'transition',
    title: 'Say goodbye to wardrobe regrets',
    subTitle: 'Stop wasting money on outfits that don’t enhance your beauty and uniqueness',
    description: 'With our personalized recommendations, you will have a functional wardrobe filled with clothes you love and can\'t wait to wear',
    image: IMAGES.TRANS_WARDROBE_REGRETS,
  },
  [QUIZ.TRANSITION_GAME]: {
    type: 'transition',
    title: 'Ready to revamp your style game?',
    subTitle: 'Answer a few more questions to define your color type and let our AI stylist find the right clothes for you.',
    description: 'You will have a perfect outfit for every occasion and look amazing without spending hours in front of the mirror',
    image: IMAGES.TRANS_GAME,
  },
  [QUIZ.TRANSITION_COLOR_TYPE]: {
    type: 'transition',
    title: 'Let\'s discover your Color Type',
    subTitle: 'We\'ve developed a smart technology to analyze your complexion and facial features.',
    image: IMAGES.TRANS_COLOR_TYPE,
  },
  [QUIZ.TRANSITION_FORMULA]: {
    type: 'transition',
    title: 'Discover your unique style formula',
    subTitle: 'With your custom capsule and color type, dressing your best has never been easier.',
    description: 'Our AI stylist has crafted a personalized wardrobe for you, where every piece perfectly pairs together',
    image: IMAGES.TRANS_FORMULA,
  },
  [QUIZ.TRANSITION_EVERY_OUTFIT]: {
    type: 'transition',
    title: 'Feel confident and comfortable in every outfit',
    subTitle: 'Unlock your personalized style guide now and get ready to feel more beautiful, powerful, and stylish',
    image: IMAGES.TRANS_EVERY_OUTFIT,
  },
  AGE: {
    type: 'single',
    title: 'How old are you?',
    options: {
      "under_18": "Under 18 years",
      "18_29": "18–29 years",
      "30_39": "30–39 years",
      "40_49": "40–49 years",
      "over_50": "50+ years",
    },
  },
  MAIN_GOAL: {
    type: 'single',
    title: 'What is your main goal when getting dressed in the morning?',
    options: {
      "beautiful": "Looking beautiful",
      "confident": "Feeling confident",
      "comfortable": "Feeling comfortable",
      "professional": "Looking professional",
      "personality": "Expressing my personality",
      "through_day": "Just getting through the day",
      "other": "Other",
    },
    images: {
      "beautiful": IMAGES.LOVE,
      "confident": IMAGES.OK,
      "comfortable": IMAGES.CUTE,
      "professional": IMAGES.COOL,
      "personality": IMAGES.PERSONALITY,
      "through_day": IMAGES.THROUGH_DAY,
      "other": IMAGES.OTHER_SMALL,
    },
  },
  OUTFIT_FOR_DAY: {
    type: 'single',
    title: 'How much time do you typically spend choosing your outfit for the day?',
    options: {
      "less_5": "Up to 5 minutes",
      "5_10": "5-10 minutes",
      "10_30": "10-30 minutes",
      "more_30": "More than 30 minutes",
    },
  },
  BIGGEST_CHALLENGES: {
    type: 'multi',
    title: 'What are the biggest challenges you face when it comes to style?',
    options: {
      "outfits": "Mixing and matching outfits",
      "personal_style": "Defining my personal style",
      "body_type": "Dressing for my body type",
      "palette": "Сhoosing a color palette",
      "events": "Dressing up for events",
      "trends": "Keeping up with trends",
      "other": "Other",
    },
    images: {
      "outfits": IMAGES.OUTFITS,
      "personal_style": IMAGES.PERSONAL_STYLE,
      "body_type": IMAGES.BODY_TYPE,
      "palette": IMAGES.PALETTE,
      "events": IMAGES.EVENTS,
      "trends": IMAGES.TRENDS,
      "other": IMAGES.OTHER_SMALL,
    },
  },
  STRUGGLE_PICKING_OUT: {
    type: 'multi',
    title: 'In what situations do you struggle with picking out an outfit?',
    options: {
      "work": "Work events",
      "date": "Date nights",
      "casual": "Casual outings",
      "special": "Special events",
      "vacations": "Vacations",
      "everyday": "Everyday wear",
      "other": "Other",
      "none": "None",
    },
    images: {
      "work": IMAGES.WORK,
      "date": IMAGES.EVENTS,
      "casual": IMAGES.CASUAL,
      "special": IMAGES.SPECIAL,
      "vacations": IMAGES.VACATIONS,
      "everyday": IMAGES.EVERYDAY,
      "other": IMAGES.OTHER_SMALL,
      "none": IMAGES.NONE,
    },
  },
  MOST_FRUSTRATES: {
    type: 'multi',
    title: 'What frustrates you most when shopping for clothes?',
    options: {
      "size": "Finding the right fit and size",
      "suit": "Finding clothes that suit me",
      "quality": "Finding clothes of good quality",
      "trendy": "Choosing stylish and trendy pieces",
      "together": "Finding pieces that go well together",
      "unneeded": "Wasting money on unneeded clothes",
      "overwhelmed": "Feeling overwhelmed by choices",
      "other": "Other",
    },
  },
  MOST_COMFORTABLE: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What kind of clothes do you feel most comfortable in?',
    options: {
      "jeans": "Jeans and\nT-shirts",
      "skirts": "Skirts and\nblouses",
      "suits": "Suits and\nblazers",
      "shorts": "Shorts and tank\ntops",
      "leggings": "Leggings and\nsweaters",
      "sweatpants": "Sweatpants and\nhoodies",
      "none": "None of the\nabove",
    },
    images: {
      "jeans": IMAGES.JEANS,
      "skirts": IMAGES.SKIRTS,
      "suits": IMAGES.SUITS,
      "shorts": IMAGES.SHORTS,
      "leggings": IMAGES.LEGGINGS,
      "sweatpants": IMAGES.SWEATPANTS,
      "none": IMAGES.BIG_NONE,
    },
  },
  MOST_RESONATES: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What style resonates with you the most?',
    options: {
      "casual_style": "Casual",
      "business": "Business casual",
      "glamorous": "Glamorous",
      "minimalistic": "Minimalistic",
      "colorful": "Colorful",
      "elegant": "Elegant",
      "bohemian": "Bohemian",
      "grunge": "Grunge",
      "preppy": "Preppy",
      "sporty": "Sporty",
      "other_style": "Other",
    },
    images: {
      "casual_style": IMAGES.CASUAL_STYLE,
      "business": IMAGES.BUSINESS,
      "glamorous": IMAGES.GLAMOROUS,
      "minimalistic": IMAGES.MINIMALISTIC,
      "colorful": IMAGES.COLORFUL,
      "elegant": IMAGES.ELEGANT,
      "bohemian": IMAGES.BOHEMIAN,
      "grunge": IMAGES.GRUNGE,
      "preppy": IMAGES.PREPPY,
      "sporty": IMAGES.SPORTY,
      "other_style": IMAGES.OTHER_STYLE,
    },
  },
  OUTFIT_YOU_LOVE: {
    type: 'single',
    title: 'How do you usually feel when you put on an outfit you love?',
    options: {
      "confident": "Confident",
      "comfortable": "Comfortable",
      "stylish": "Stylish",
      "powerful": "Powerful",
      "beautiful": "Beautiful",
      "all": "All of the above",
      "other": "Other"
    },
    images: {
      "confident": IMAGES.OK,
      "comfortable": IMAGES.CUTE,
      "stylish": IMAGES.COOL,
      "powerful": IMAGES.POWERFUL,
      "beautiful": IMAGES.LOVE,
      "all": IMAGES.CUTE,
      "other": IMAGES.OTHER_SMALL,
    },
  },
  FEELING_INSECURE: {
    type: 'single',
    title: 'Do you agree that feeling insecure about your looks makes it harder to succeed both in career and personal life?',
    options: {
      "yes": "Yes",
      "no": "No",
      "not_sure": "Not sure",
    }
  },
  HOW_CONFIDENT: {
    type: 'single',
    kind: QuizStepKind.Numeric,
    title: 'On a scale of 1-5, how confident do you feel in your current outfits?',
  },
  EYES_COLOR: {
    type: 'single',
    title: 'What color are your eyes?',
    options: {
      "brown": "Brown",
      "green": "Green",
      "blue": "Blue",
      "gray": "Gray",
      "different": "Different",
      "other": "Other",
    },
    images: {
      "different": IMAGES.DIFFERENT,
      "other": IMAGES.OTHER_SMALL,
    },
    colors: {
      "brown": "#936D4B",
      "green": "#71C07E",
      "blue": "#5BC0F9",
      "gray": "#DEDEDE",
    }
  },
  HAIR_COLOR: {
    type: 'single',
    title: 'What is your hair color?',
    options: {
      "blonde": "Blonde",
      "black": "Black",
      "brown": "Brown",
      "red": "Red",
      "gray": "Gray",
      "other": "Other",
    },
    images: {
      "other": IMAGES.OTHER_SMALL,
    },
    colors: {
      "blonde": "#FFE5A1",
      "black": "#2E2E2E",
      "brown": "#936D4B",
      "red": "#EE5E4B",
      "gray": "#DEDEDE",
    }
  },
  SKIN_TONE: {
    type: 'single',
    title: 'What is your skin tone?',
    options: {
      "fair": "Fair",
      "ivory": "Ivory",
      "sand": "Sand",
      "porcelain": "Porcelain",
      "sienna": "Sienna",
      "band": "Band",
      "umber": "Umber",
      "espresso": "Espresso",
      "other": "Other",
    },
    images: {
      "other": IMAGES.OTHER_SMALL,
    },
    colors: {
      "fair": "#FFE6D5",
      "ivory": "#E2CCAB",
      "sand": "#E6C899",
      "porcelain": "#F1D9BE",
      "sienna": "#C69F7F",
      "band": "#A58B68",
      "umber": "#A46B4D",
      "espresso": "#593B1B",
    }
  },
  BODY_SHAPE: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'Which of these best describes your body shape?',
    options: {
      "pear": "Pear",
      "apple": "Apple",
      "hourglass": "Hourglass",
      "rectangle": "Rectangle",
      "triangle": "Inverted triangle",
      "dont_know": "I wish I knew",
    },
    images: {
      "pear": IMAGES.PEAR,
      "apple": IMAGES.APPLE,
      "hourglass": IMAGES.HOURGLASS,
      "rectangle": IMAGES.RECTANGLE,
      "triangle": IMAGES.TRIANGLE,
      "dont_know": IMAGES.OTHER_STYLE,
    },
  },
  CLOTHES_SAY: {
    type: 'single',
    title: 'What do you want your clothes to say about you?',
    options: {
      "professional": "Professional and confident",
      "powerful": "Powerful and successful",
      "young": "Young and vibrant",
      "chic": "Effortlessly chic",
      "fun": "Fun and playful",
      "none": "None of the above",
    },
  },
  WARDROBE: {
    type: 'multi',
    title: 'What do you hope to achieve with your new personalized wardrobe?',
    options: {
      "boost": "Self-confidence boost",
      "budget": "Savings on my budget",
      "shopping": "Stress-free shopping",
      "morning": "More time in the morning",
      "impressions": "Better first impressions",
      "style": "A unique personal style",
      "other": "Other",
    },
    images: {
      "boost": IMAGES.OK,
      "budget": IMAGES.BUDGET,
      "shopping": IMAGES.SHOPPING,
      "morning": IMAGES.MORNING,
      "impressions": IMAGES.LOVE,
      "style": IMAGES.STYLE,
      "other": IMAGES.OTHER_SMALL,
    },
  },
  AI_STYLIST: {
    type: 'multi',
    title: 'What are you most excited to get from our AI stylist?',
    options: {
      "analysis": "Color analysis",
      "wardrobe": "A capsule wardrobe",
      "suggestions": "Outfit suggestions",
      "recommendations": "Style recommendations",
      "tips": "Tips to boost confidence",
      "inspiration": "Fashion inspiration",
      "advice": "Shopping advice",
      "other": "Other",
    },
  }
}

export const STEPS_LIGHT: Record<string, QuizStep> = {
  [QUIZ.MAIN_GOAL]: {
    type: 'multi',
    title: 'What is your main goal when getting dressed in the morning?',
    options: {
      "beautiful": "Looking beautiful",
      "confident": "Feeling confident",
      "comfortable": "Feeling comfortable",
      "professional": "Looking professional",
      "personality": "Expressing my personality",
      "through_day": "Just getting through the day",
      "other": "Other",
    }
  },
  [QUIZ.FLATTER_COLORS]: {
    type: 'single',
    title: 'Do you know which colors and styles flatter you?',
    options: {
      "yes": "Yes",
      "no": "No",
      "50_50": "50/50",
    },
  },
  [QUIZ.FEEL_CONFIDENT]: {
    type: 'single',
    title: 'Do you feel confident in your current outfits?',
    options: {
      "yes": "Yes",
      "no": "No",
      "50_50": "50/50",
    },
  },
  [QUIZ.CURRENT_WARDROBE]: {
    type: 'single',
    title: 'How much of your current wardrobe do you wear regularly?',
    options: {
      "10": "10%",
      "25": "25%",
      "50": "50%",
      "75": "75%",
      "more_90": "More than 90%",
    },
  },
  [QUIZ.FEEL_ANXIOUS]: {
    type: 'single',
    title: 'Do you often feel anxious about what outfit to choose?',
    options: {
      "yes": "Yes, it's a daily struggle",
      "depends_occasion": "It depends in the occasion",
      "usually_trust": "I usually trust my sense of style",
      "no": "It doesn’t bother me much",
    },
  },
  [QUIZ.STRUGGLE_PICKING_OUT]: {
    type: 'multi',
    kind: QuizStepKind.Square,
    title: 'In what situations do you struggle with picking out an outfit?',
    options: {
      "work": "Work events",
      "date": "Date nights",
      "casual": "Casual outings",
      "special": "Special events",
      "vacations": "Vacations",
      "everyday": "Everyday wear",
      "other": "Other",
      "none": "None",
    },
    images: {
      "work": IMAGES.WORK_LIGHT,
      "date": IMAGES.DATE_LIGHT,
      "casual": IMAGES.CASUAL_LIGHT,
      "special": IMAGES.SPECIAL_EVENTS_LIGHT,
      "vacations": IMAGES.VACATION_LIGHT,
      "everyday": IMAGES.EVERY_DAY_LIGHT,
      "other": IMAGES.OTHER_LIGHT,
      "none": IMAGES.NONE_LIGHT,
    },
  },
  [QUIZ.MOST_FRUSTRATES]: {
    type: 'multi',
    title: 'What frustrates you most when shopping for clothes?',
    options: {
      "size": "Finding the right fit and size",
      "suit": "Finding clothes that suit me",
      "quality": "Finding clothes of good quality",
      "trendy": "Choosing stylish and trendy pieces",
      "together": "Finding pieces that go well together",
      "unneeded": "Wasting money on unneeded clothes",
      "overwhelmed": "Feeling overwhelmed by choices",
      "other": "Other",
    },
  },
  [QUIZ.WASTE_MONEY]: {
    type: 'single',
    title: 'Do you often waste money on clothes you never wear?',
    options: {
      "yes": "All the time",
      "often": "Pretty often",
      "few_times": "It's been a few times",
      "no": "Not really",
    },
  },
  [QUIZ.MOST_COMFORTABLE]: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What kind of clothes do you feel most comfortable in?',
    options: {
      "jeans": "Jeans and\nT-shirts",
      "skirts": "Skirts and\nblouses",
      "suits": "Suits and\nblazers",
      "shorts": "Shorts and tank\ntops",
      "leggings": "Leggings and\nsweaters",
      "sweatpants": "Sweatpants and\nhoodies",
      "dresses": "Dresses",
      "none": "None of the\nabove",
    },
    images: {
      "jeans": IMAGES.JEANS_LIGHT,
      "skirts": IMAGES.SKIRTS_LIGHT,
      "suits": IMAGES.SUITS_LIGHT,
      "shorts": IMAGES.SHORTS_LIGHT,
      "leggings": IMAGES.LEGGINGS_LIGHT,
      "sweatpants": IMAGES.SWEATPANTS_LIGHT,
      "dresses": IMAGES.DRESSES_LIGHT,
      "none": IMAGES.NONE_LIGHT,
    },
  },
  [QUIZ.MOST_RESONATES]: {
    type: 'single',
    kind: QuizStepKind.Square,
    view: QuizStepView.CAPTION,
    title: 'What style resonates with you the most?',
    options: {
      "casual_style": "Casual",
      "business": "Business casual",
      "glamorous": "Glamorous",
      "minimalistic": "Minimalistic",
      "colorful": "Colorful",
      "elegant": "Elegant",
      "bohemian": "Bohemian",
      "grunge": "Grunge",
      "preppy": "Preppy",
      "sporty": "Sporty",
      "other_style": "Other",
    },
    images: {
      "casual_style": IMAGES.CASUAL_STYLE,
      "business": IMAGES.BUSINESS,
      "glamorous": IMAGES.GLAMOROUS,
      "minimalistic": IMAGES.MINIMALISTIC,
      "colorful": IMAGES.COLORFUL,
      "elegant": IMAGES.ELEGANT,
      "bohemian": IMAGES.BOHEMIAN,
      "grunge": IMAGES.GRUNGE,
      "preppy": IMAGES.PREPPY,
      "sporty": IMAGES.SPORTY,
      "other_style": IMAGES.SMALL_OTHER_BUTTON,
    },
  },
  [QUIZ.WEAR_OUTFIT]: {
    type: 'multi',
    title: 'How do you usually feel when you wear an outfit that flatters you?',
    options: {
      "sparkling": "Sparkling with confidence",
      "comfortable": "Comfortable and at ease",
      "icon": " Like a fashion icon",
      "unstoppable": "Totally unstoppable",
      "next_levet": "Next-level beautiful",
      "all_above": "All of the above",
      "other": "Other"
    },
  },
  [QUIZ.BEAUTY_INSECURITIES]: {
    type: 'single',
    title: 'Do you agree that beauty insecurities make it harder to succeed both in career and personal life?',
    options: {
      "yes": "Yes",
      "no": "No",
      "not_shure": "Not sure",
    },
  },
  [QUIZ.CLOTHES_SAY]: {
    type: 'single',
    title: 'What do you want your clothes to say about you?',
    options: {
      "professional": "Professional and confident",
      "powerful": "Powerful and successful",
      "young": "Young and vibrant",
      "chic": "Effortlessly chic",
      "fun": "Fun and playful",
      "none": "None of the above",
    },
  },
  [QUIZ.HOW_CONFIDENT]: {
    type: 'single',
    kind: QuizStepKind.Numeric,
    title: 'On a scale of 1-5, how confident do you feel in your current outfits?',
  },
  [QUIZ.AGE]: {
    type: 'single',
    title: 'How old are you?',
    options: {
      "18_29": "18–29",
      "30_39": "30–39",
      "40_49": "40–49",
      "over_50": "50+",
    },
  },
  [QUIZ.BODY_SHAPE]: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What is your body shape?',
    options: {
      "hourglass": "Hourglass",
      "bottomHourglass": "Bottom Hourglass",
      "topHourglass": "Top Hourglass",
      "spoon": "Spoon",
      "invertedTriangle": "Inverted Triangle",
      "triangle": "Triangle",
      "rectangle": "Rectangle"
    },
    images: {
      "hourglass": IMAGES.HOURGLASS_LIGHT,
      "bottomHourglass": IMAGES.BOTTOM_HOURGLASS_LIGHT,
      "topHourglass": IMAGES.TOP_HOURGLASS_LIGHT,
      "spoon": IMAGES.SPOON_LIGHT,
      "invertedTriangle": IMAGES.INVERTED_TRIANGLE_LIGHT,
      "triangle": IMAGES.TRIANGLE_LIGHT,
      "rectangle": IMAGES.RECTANGLE_LIGHT
    },
  },
  [QUIZ.EYES_COLOR]: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What color are your eyes?',
    options: {
      "black": "Black",
      "brown": "Brown",
      "lightBrown": "Light brown",
      "grayBrown": "Gray brown",
      "hazel": "Hazel",
      "blue": "Blue",
      "green": "Green",
      "gray": "Gray",
      "darkGray": "Dark gray"
    },
    colors: {
      "black": "#181B26",
      "brown": "#6C5023",
      "lightBrown": "#997E4F",
      "grayBrown": "#76655E",
      "hazel": "#6C6753",
      "blue": "#7D98B0",
      "green": "#7F937A",
      "gray": "#B8BBBC",
      "darkGray": "#70767A"
    }
  },
  [QUIZ.HAIR_COLOR]: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'Which hair color is most similar to yours?',
    options: {
      "ashBlonde": "Ash blonde",
      "goldenBlonde": "Golden blonde",
      "lightBrown": "Light brown",
      "darkBrown": "Dark brown",
      "copper": "Copper",
      "burgundy": "Burgundy",
      "black": "Black",
    },
    colors: {
      "ashBlonde": "#EEE8D3",
      "goldenBlonde": "#FBDA83",
      "lightBrown": "#B08965",
      "darkBrown": "#603B06",
      "copper": "#C5523B",
      "burgundy": "#620431",
      "black": "#2E2E2E",
    }
  },
  [QUIZ.SKIN_TONE]: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What is your skin tone?',
    options: {
      "fair": "Fair",
      "ivory": "Ivory",
      "sand": "Sand",
      "porcelain": "Porcelain",
      "sienna": "Sienna",
      "band": "Band",
      "umber": "Umber",
      "espresso": "Espresso",
    },
    colors: {
      "fair": "#FFE6D5",
      "ivory": "#E2CCAB",
      "sand": "#E6C899",
      "porcelain": "#F1D9BE",
      "sienna": "#C69F7F",
      "band": "#A58B68",
      "umber": "#A46B4D",
      "espresso": "#593B1B",
    }
  },
}

export const getSteps = (theme?: ThemeMode) => {
  if (theme === 'light') {
    return Object.assign({}, STEPS, STEPS_LIGHT)
  }

  return STEPS
}


export type StyleSteps = keyof typeof STEPS;