import { initializeApp as initFirebase } from 'firebase/app';

import { parseUrlParams } from '@web-solutions/core/utils/url-sync';

import { render } from './render';

parseUrlParams();

let pType = window.location.pathname.split('/')[1];

let promiseApp;

switch (pType) {
  case 'terminate':
    promiseApp = import('./screens/terminate').then(r => r.default);
    break;
  case 'manage':
    promiseApp = import('./screens/manage').then(r => r.default);
    break;
  case 'quiz-book':
    promiseApp = import('./screens/quiz/book').then(r => r.default);
    break;
  default:
    promiseApp = import('./screens/quiz').then(r => r.default);
    break;
}

initFirebase({
  apiKey: "AIzaSyD4Oj2ZGHxnl66Nh5AJAtHAXHx8Iu4exQI",
  authDomain: "stylix-web.firebaseapp.com",
  projectId: "stylix-web",
  storageBucket: "stylix-web.appspot.com",
  messagingSenderId: "859463491805",
  appId: "1:859463491805:web:1893a84738ca1932eefb34",
  measurementId: "G-Y1EH5LPR4F"
});

promiseApp.then(render);
