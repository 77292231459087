import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';

import { Text } from '@web-solutions/core/ui-elements';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { t, tm, } from '../../../../localization';
import { SubscriptionStatus } from '../../../types';

import { ReactComponent as ArrowBackIcon } from '../../../components/icons/arrow-back.svg';

import { useManageContext } from '../../../hooks/use-manage-context';
import { selectProduct, selectSubscription } from '../../../slice';

import classes from './style.module.scss';

const tKey = 'manage.current_plan';

interface CurrentPlanProps {
  onCancelClick?: () => void;
  isPlanDetailsAvailable?: boolean;
  isSubUpdating?: boolean;
  withoutBgImage?: boolean;
}

export const CurrentPlan: React.FC<CurrentPlanProps> = ({ onCancelClick, isPlanDetailsAvailable, isSubUpdating, withoutBgImage }) => {

  const subscription = useSelector(selectSubscription);
  const product = useSelector(selectProduct);
  const { currentPlanContent } = useManageContext();

  const { mngSubMainPage: config } = useRemoteConfig();

  const [expanded, setExpanded] = useState(!config?.planDetailsEnabled);

  if (!subscription || !product) {
    return null;
  }

  const handleMoreClick = () => {
    Analytics.trackEvent('manage_main_more_less', EVENT_ACTION.CLICK);
    setExpanded(!expanded);
  }

  return (
    <div className={classNames(classes.wrap, { [classes.expanded]: expanded, [classes.bgWithoutImg]: withoutBgImage })}>
      <div className={classes.headerWrapper}>
        <h2>{t(`${tKey}.title`)}</h2>
        {isSubUpdating &&
          <div className={classes.updatingBadge}>
            {t(`${tKey}.updating_badge`)}
          </div>
        }
      </div>
      {isPlanDetailsAvailable && <>
        <button onClick={handleMoreClick} className={classes.more_button}><ArrowBackIcon /></button>
        {currentPlanContent}
      </>}
      <div className={classes.content_container}>
        <div className={classes.row}>
          <Text className={classes.left}>{t(`${tKey}.status`)}</Text>
          <Text
            className={classNames(classes.right, {
              [classes.canceled]: subscription.status === SubscriptionStatus.CANCELED || subscription.status === SubscriptionStatus.EXPIRED
            })}>
            {t(`manage.status.${subscription.status}`)}
          </Text>
        </div>
        {product.period && (
          <div className={classes.row}>
            <Text className={classes.left}>{t(`${tKey}.period`)}</Text>
            <Text className={classes.right}>{t(`manage.period.${product.period}`)}</Text>
          </div>
        )}
        {expanded && <>
          <div className={classes.row}>
            <Text className={classes.left}>{t(`${tKey}.price`)}</Text>
            <Text className={classes.right}>{tm(config.currentPlanPrice, '', { ...product })}</Text>
          </div>
          {subscription.status === SubscriptionStatus.CANCELED
            ? (
              <div className={classes.row}>
                <Text className={classes.left}>{t(`${tKey}.expire`)}</Text>
                <Text className={classes.right}>{dayjs(subscription.expire_at).format('LL')}</Text>
              </div>
            ) :
            subscription.status === SubscriptionStatus.EXPIRED
              ? (
                <div className={classes.row}>
                  <Text className={classes.left}>{t(`${tKey}.expired`)}</Text>
                  <Text className={classes.right}>{dayjs(subscription.expire_at).format('LL')}</Text>
                </div>
              )
              : null}
          {isPlanDetailsAvailable && !isSubUpdating && <button onClick={onCancelClick} className={classes.cancel_button}>{t(`${tKey}.cancel`)}</button>}
        </>}
      </div>
      {!isPlanDetailsAvailable && currentPlanContent}
    </div>
  );
};
