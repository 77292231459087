import queryString from 'query-string';
import { ThunkAction } from 'redux-thunk';

import { setPurchase } from '@web-solutions/core/store/billing/actions';
import { remoteConfigSelector } from '@web-solutions/core/store/remote-config/selectors';
import { REDIRECT_TO_BROWSER, doRedirectToBrowser } from '@web-solutions/core/utils/redirect-to-browser';
import { type Purchase } from '@web-solutions/core/interfaces/billing';

//@ts-ignore
import { selectFlow } from 'src/store/routing/selectors';
//@ts-ignore
import { processEmail } from 'src/store/profile/actions';

import { SUBSCRIPTION } from '../../constants/routes';


import { routingSlice } from '.';

export const { setCompleted, setStep, setStartRoute } = routingSlice.actions;

export const init = (): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch, getState) => {
  const { redirectToBrowser } = remoteConfigSelector(getState());
  const flow = selectFlow(getState());

  if (redirectToBrowser === REDIRECT_TO_BROWSER.ENABLED) {
    doRedirectToBrowser(redirectToBrowser);
  }

  const params = queryString.parse(window.location.search);

  if (params.completed !== undefined) {
    dispatch(setCompleted(true));
  }

  if (params.purchased !== undefined) {
    dispatch(setPurchase({} as Purchase));
  }

  if (params.email) {
    dispatch(processEmail(params.email as string));
  }

  const {
    routing: { isCompleted },
  } = getState();

  dispatch(setStep(0));

  const startRoute = !isCompleted ? `/${flow[0]}` : await dispatch(getRouteAfterFlow());
  dispatch(setStartRoute(startRoute));

  return Promise.resolve();
};

export const getRouteAfterFlow = (): ThunkAction<string, any, unknown, any> => (dispatch, getState) => {
  const {
    billing: { purchased },
    remoteConfig: { presummaryStack },
  } = getState();

  if (purchased) {
    return SUBSCRIPTION.SUCCESS
  } else if (presummaryStack?.length) {
    return SUBSCRIPTION.PRESUMMARY;
  } else {
    return SUBSCRIPTION.MAIN;
  }
};
